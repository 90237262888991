<template>
  <v-app>
    <v-main
      style="background-color:#eee;background:url('/img/site.png') repeat;background-position:center;background-size:cover;position: fixed;height: 100vh;width: 100vw;overflow: visible;z-index: 100;margin:0 auto;"
    >
    </v-main>
  </v-app>
</template>

<script>
export default {
  computed: {
    xs() {
      return this.$vuetify.breakpoint.xsOnly;
    }
  }
};
</script>

<style>
@import "./style/fonts.css";
@import "./style/main.css";
@import "./style/custom-user.css";
@import "./style/custom-vuetify.css";
</style>
